import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticateByLoginPasswordRS, AuthModelLoginPassword, CheckOTPCodeAndLoginRQ, CheckOTPCodeAndLoginRS, ClientService, IAuthModelLoginPassword, ICheckOTPCodeAndLoginRQ, UserIdentityGetLoggedUserRS } from './client.service';
import { SidebarService } from './sidebar.service';
import { CookieService } from 'ngx-cookie-service';
import { JwtService } from './jwt.service';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserIdentityGetLoggedUserRS>;
  public currentUser: Observable<UserIdentityGetLoggedUserRS>;

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable()
  constructor
    (
      private http: HttpClient,
      private router: Router,
      private clientService: ClientService,
      private jwtService: JwtService,
      private sideBarService: SidebarService,
      private cookieService: CookieService,
      private translate : TranslateService,
      private utilsService: UtilsService
  ) {
    this.currentUserSubject = new BehaviorSubject<UserIdentityGetLoggedUserRS>(JSON.parse(window.sessionStorage?.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserIdentityGetLoggedUserRS {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string, skipOtp: boolean): Observable<AuthenticateByLoginPasswordRS> {
    let loginData: IAuthModelLoginPassword;
    loginData = {
      login: email,
      password: password
    };
    return this.clientService.authenticateByLoginPassword(new AuthModelLoginPassword(loginData)).pipe(
      map(
        authEntity => {

          if(skipOtp)
          {
            this.jwtService.saveToken(authEntity);
            this.isAuthenticatedSubject.next(true);

            this.populate()
          }

          this.utilsService.clearFilterStorage();
          
          return authEntity;
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      )
    )
  }

  checkOTPAndLogin(email: string, password: string, otpCode: string): Observable<Promise<CheckOTPCodeAndLoginRS | null>> {
    let loginData: ICheckOTPCodeAndLoginRQ;

    loginData = {
      login: email,
      password: password,
      otpCode: otpCode
    };


    return this.clientService.checkOTPCodeAndLogin(new CheckOTPCodeAndLoginRQ(loginData)).pipe(
      map(
        async authEntity => {
          this.jwtService.saveToken(authEntity);
          this.isAuthenticatedSubject.next(true);

          await this.populate()

          return authEntity;
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      ));
  }

  logout(isError: boolean) {    
    //CLEAR FILTERS
    this.utilsService.clearFilterStorage();
    this.clientService.logout().toPromise();
    this.purgeAuth();
    this.router.navigate(['/login']);
  }

  async populate(){
      if (this.jwtService.getCurrentToken()) {
        const currentUser = await this.clientService.getLoggedUser().toPromise();
        const parameter = await this.clientService.getForBackOfficeAfterAuth().toPromise();
        sessionStorage.setItem('parametersAfterAuth', JSON.stringify(parameter));
        
        if(currentUser){
          if (currentUser && currentUser.cultureIsoCode) {
            
            const newLang = currentUser.cultureIsoCode;
            const presentLang = this.utilsService.getDefaultLanguage();
            if(presentLang != newLang){
              console.log("Default lang not setted, set nurseCultureIsoCode: " + newLang);
              const translations = await this.utilsService.getAllTranslationsByLangCode(newLang).toPromise()
              this.translate.setTranslation(newLang.slice(0,2), JSON.parse(translations), true);
              this.translate.use(newLang.slice(0,2));
              this.utilsService.setDefaultLanguage(newLang);
            }

            //this.cookieService.set('defaultLang', currentUser.cultureIsoCode);
            //window.localStorage.clear(); 22/12/23: Is causing problems with reflection of new language when changed
            this.cookieService.set('defaultCountry', currentUser.countryIsoCode || "DE");
            this.sideBarService.showSidebar();
          }

          this.currentUserSubject.next(currentUser);
          this.jwtService.saveCurrentUser(currentUser);
        }
      } else {
        this.purgeAuth();
      }
  }

  refreshCurrentUserAndGoHome() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getCurrentToken()) {
      this.clientService.getLoggedUser().subscribe(
        data => {
          this.currentUserSubject.next(data);
          this.jwtService.saveCurrentUser(data);
          this.router.navigate(['/']);
          this.sideBarService.showSidebar();
        },
        err => console.log(err)
      );
    } else {
      this.purgeAuth();
    }
  }

  purgeAuth() {
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as UserIdentityGetLoggedUserRS);
    this.isAuthenticatedSubject.next(false);
  }

  async changePasswordFlow() {
    this.jwtService.removeUser();
    await this.populate();
  }

  changeLanguageFlow(){
    this.populate();
  }

  getAllCountryPhonePrefix(idEntity: any, formInfo: any, formAdd: any) {
    this.clientService.countryPhonePrefixGetAll().subscribe(
      response => {
        formInfo.autocompleteCountryPhonePrefixConfig.array = response;

        if(idEntity == undefined && this.currentUserValue.countryIsoCode != ""){
          let correctPrefix = formInfo.autocompleteCountryPhonePrefixConfig.array.filter((x: { countryIsoCode: any; }) => x.countryIsoCode == this.currentUserValue.countryIsoCode);
          formAdd.patchValue({
            phonePrefix: { phonePrefix: correctPrefix[0]?.phonePrefix, countryIsoCode: correctPrefix[0]?.countryIsoCode },
          });
          formInfo!.autocompleteCountryPhonePrefixConfig.value = { phonePrefix: correctPrefix[0]?.phonePrefix, countryIsoCode: correctPrefix[0]?.countryIsoCode };
          formInfo!.autocompleteCountryPhonePrefixConfig = {...formInfo!.autocompleteCountryPhonePrefixConfig};
        }
      },
      error => console.log(error)
    )
  }
}