import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService, EntityRQ, IPrescriptionHistoryGetV2RQ, PrescriptionHistoryGetV2RQ } from 'src/app/core';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrescriptionDetailComponent implements OnInit {
  id?: string;
  entityVersions: any = [];
  historySyncronizations: any = [];
  activeTab = 'ENTITY_VERSIONS';

  loading: boolean = true;

  constructor(
    private location: Location,
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();
    this.getPrescriptionEntityVersion();
  }

  backToList(){   
    this.location.back();
  }

  currentEntityVersionPage: number = 1;
  currentHistorySynchronizationPage: number = 1;

  totalItemsEntityVersion: number = 0;
  totalItemsHistorySynchronization: number = 0;
  
  tableEntityVersionConfig =  {
    actions: true,
    gridData: this.entityVersions,
    pagination: {
      id: "tableEntityVersion",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsEntityVersion
    },
    sort:{
      field: "versionDate",
      direction: "asc"
    },
    notes: false
  };
  
  tableHistorySynchronizationConfig =  {
    actions: true,
    gridData: this.historySyncronizations,
    pagination: {
      id: "tableHistorySynchronization",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsHistorySynchronization
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }

  getPrescriptionHistory(event: any){
    if (event?.nextId == "ENTITY_VERSIONS"){
      this.getPrescriptionEntityVersion();
    }
    else if (event?.nextId == "CMS_SYNCRONIZATIONS"){
      this.getPrescriptionHistorySynchronization();
    }
  }

  pageEntityVersionChanged(pageNumber: any){
    this.currentEntityVersionPage = Number.parseInt(pageNumber);
    this.getPrescriptionEntityVersion();
  }

  getPrescriptionEntityVersion() {
    this.loading = true;

    let input: IPrescriptionHistoryGetV2RQ = {
      idPrescription: this.id,
      pageIndex: this.currentEntityVersionPage
    }
    this.clientService.prescriptionEntityVersionGetAll(new PrescriptionHistoryGetV2RQ(input)).subscribe(
      response =>{
        let versionsList: any[] = [];
        for(let entity of response.list!){
          var jsonObj = JSON.parse(entity.jsonData || "");
          const jsonObj1 = Object.assign({VersionDate: entity.versionDate?.toISOString(), MethodName: entity.methodName}, jsonObj);
          versionsList.push(jsonObj1);
        }
        this.entityVersions = versionsList;
        this.tableEntityVersionConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableEntityVersionConfig.pagination.totalItems = response.count || 0;
        this.tableEntityVersionConfig.pagination.currentPage = this.currentEntityVersionPage;
        this.tableEntityVersionConfig.gridData = this.entityVersions;
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }
  
  pageHistorySynchronizationChanged(pageNumber: any){
    this.currentHistorySynchronizationPage = Number.parseInt(pageNumber);
    this.getPrescriptionHistorySynchronization();
  }

  getPrescriptionHistorySynchronization() {
    this.loading = true;

    let input: IPrescriptionHistoryGetV2RQ = {
      idPrescription: this.id,
      pageIndex: this.currentHistorySynchronizationPage
    }
    this.clientService.prescriptionHistorySynchronizationGetAll(new PrescriptionHistoryGetV2RQ(input)).subscribe(
      response =>{
        this.historySyncronizations = response.list || [];
        this.tableHistorySynchronizationConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableHistorySynchronizationConfig.pagination.totalItems = response.count || 0;
        this.tableHistorySynchronizationConfig.pagination.currentPage = this.currentHistorySynchronizationPage;
        this.tableHistorySynchronizationConfig.gridData = this.historySyncronizations;
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }
}