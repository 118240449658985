<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="orderWindow">
        
        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>
        
        <h2 class="mt-4">Order details</h2>
        
        <div class="col-lg-3 col-md-auto">
            <app-date-picker [datePickerConfig]="datePickerConfig!" (resultDate)="inputValues($event)"></app-date-picker>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>DateDue:</b>
            </div>
            <div class="col-6">
                {{orderWindow.dateDue | date: 'dd/MM/yyyy | HH:mm'}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>DateDeliveryDesired:</b>
            </div>
            <div class="col-6">
                {{orderWindow.dateDeliveryDesired | date: 'dd/MM/yyyy | HH:mm'}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>Window:</b>
            </div>
            <div class="col-6">
                {{orderWindow.orderEditableDateFrom | date: 'dd/MM/yyyy | HH:mm'}} - {{orderWindow.orderEditableDateTo | date: 'dd/MM/yyyy | HH:mm'}}
                ({{orderWindow.type}})
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>IsOrderDay:</b>
            </div>
            <div class="col-6">
                {{orderWindow.isOrderDay}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>Patient Addresses:</b>
            </div>
            <div class="col-6">
                <div *ngFor="let address of orderWindow.patientAddresses;let i=index">
                    {{i}})<br/>
                    FullAddress: {{address.fullAddress}} ({{address.type}}) <br/>
                    MinDeliveryDate: {{address.minDeliveryDate | date: 'dd/MM/yyyy'}} <br/>
                    MinDeliveryDatePatient: {{address.minDeliveryDatePatient | date: 'dd/MM/yyyy'}} <br/>
                    MinEmergencyDeliveryDate: {{address.minEmergencyDeliveryDate | date: 'dd/MM/yyyy'}} <br/>
                    DeliveryDesiredTimeWindow: {{address.deliveryDesiredTimeWindow}} <br/>
                    CustomDeliveryDays: {{address.customDeliveryDays}}<br/>                           
                </div>
            </div>            
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>IsEmergencyOrder:</b>
            </div>
            <div class="col-6">
                {{orderWindow.isEmergencyOrder}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>IsExtraOrder:</b>
            </div>
            <div class="col-6">
                {{orderWindow.isExtraOrder}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3">
                <b>Parameter:</b>
            </div>
            <div class="col-6">
                DaysBeforeDueDateStart: {{orderWindow.daysBeforeDueDateStart}} <br/>
                DaysBeforeDueDateEnd: {{orderWindow.daysBeforeDueDateEnd}} <br/>
                HoursToAdd: {{orderWindow.hoursToAdd}} <br/>
                DaysRoutineOverDue: {{orderWindow.daysRoutineOverDue}} <br/>
                IsDeliveryDateSettable: {{orderWindow.isDeliveryDateSettable}} <br/>
                AvailableDeliveryWeekDay: {{orderWindow.daysWithoutOrder}} <br/>
                PublicHolidays:<br/>
                <span *ngFor="let item of publicHolidaysArray"> {{item | date: 'dd/MM/yyyy'}} </span><br/>
                ConsiderCustomPatientDayForEmergencyOrder: {{orderWindow.considerCustomDays}} <br/>
            </div>
        </div>

        <br/>
        
        <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" (navChange)="getOrderHistory($event)">
            <!-- ENTITY_VERSIONS -->
            <ng-container ngbNavItem [ngbNavItem]="'ENTITY_VERSIONS'">
                <a ngbNavLink>
                    <app-text [text]="'ORDER_ENTITY_VERSION_LABEL'|translate" [textType]="activeTab === 'ENTITY_VERSIONS' ? 'BLUE' : 'GREY' " [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <div *ngIf="this.loadingHistory ; else contentEntity" class="spinner-box">
                        <spinner></spinner>
                    </div>
                    <ng-template #contentEntity>
                        <app-table-history [tableConfig]="tableEntityVersionConfig" (pageChanged)="pageEntityVersionChanged($event)"></app-table-history>
                    </ng-template>
                </ng-template>
            </ng-container>
            <!-- HISTORY SYNCRONIZATIONS -->
            <ng-container ngbNavItem [ngbNavItem]="'CMS_SYNCRONIZATIONS'">
                <a ngbNavLink>
                    <app-text [text]="'ORDER_CMS_SYNC_LABEL'|translate" [textType]="activeTab === 'CMS_SYNCRONIZATIONS' ? 'BLUE' : 'GREY'" [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <div *ngIf="this.loadingHistory ; else contentSynchronization" class="spinner-box">
                        <spinner></spinner>
                    </div>
                    <ng-template #contentSynchronization>
                        <app-table-history [tableConfig]="tableHistorySynchronizationConfig" (pageChanged)="pageHistorySynchronizationChanged($event)"></app-table-history>
                    </ng-template>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>
</ng-template>