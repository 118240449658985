import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  AuthenticationService, BackOfficeOrderView, ClientService, IOrderGetAllBackOfficeRQ, OrderGetAllBackOfficeRQ } from 'src/app/core';
import { Location } from '@angular/common';
import { Role } from 'src/app/core/enum/roles';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {

  id?: string;
  orderList! : BackOfficeOrderView[];  
  loading: boolean = true;
  record?: any;
  totalItemsOrder: number = 0;

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_ORDER')}

  tableConfig =  {
    actions: true,
    gridData: this.orderList,
    headerList: [
      {name: this.getTranslation('DateDue'), field: 'dateDue', sortable: true, isDate: true},
      {name: this.getTranslation('DateDeliveryDesired'), field: 'dateDeliveryDesired', sortable: true, isDate: true},
      {name: this.getTranslation('DateSaved'), field: 'dateSaved', sortable: true, isDate: true},
      {name: this.getTranslation('DateSubmitted'), field: 'dateSubmitted', sortable: true, isDate: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableOrder",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsOrder
    },
    sort:{
      field: "dateDue",
      direction: "desc"
    },
    notes: false
  };

  anyResult: boolean = false;

  constructor(
    private clientService: ClientService,
    private authService: AuthenticationService,
    private router : Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private location: Location,
  ) { }


  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;
    this.getOrderList();
  }

  backToList(){   
    this.location.back();
  }

  filterName?: string;
  filterClinic?: any;

  orderDirection?: string = "dateDue_desc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getOrderList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterClinic = value.target.value.idClinic;
      this.getOrderList(); 
    }
    else{
      this.filterClinic = null;
      this.getOrderList(); 
    } 
  }
 
  getOrderList() {
    let input: IOrderGetAllBackOfficeRQ = {
      idPatient: this.id,
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName
    }
    this.clientService.orderGetAllBackOffice(new OrderGetAllBackOfficeRQ(input)).subscribe(
      response =>{
        this.orderList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.orderList;
        if (this.authService.currentUserValue.role !== Role.AppSupport){
          this.bindButtons(this.tableConfig.gridData);
        }
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderOrderChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getOrderList();
  }

  pageOrderChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getOrderList();
  }

  getUpdatedOrderList(): any{
    return this.getOrderList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToOrderDetail(event: any) {
    if(event.action == 'edit' && this.authService.currentUserValue.role !== Role.AppSupport){
      this.router.navigate(['/orders/detail', event.record.idOrder]);
    }
  }

  bindButtons(orderList: BackOfficeOrderView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let order of orderList){
      this.tableConfig.recordButtonConfigs.set(order,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}